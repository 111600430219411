import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import AmberlyLogo from '../img/amberlylocal.png'
import "./menu.css"
import axios from 'axios'

export const MenuPageTemplate = () => {
  const [typeToItemMap, setTypeToItemMap] = useState({})
  // get apiKey in a secure way
  const apiKey = process.env.GATSBY_AIRTABLE_API_KEY
  // get data from airtable
  useEffect(() => {
    axios.get(`https://api.airtable.com/v0/appOO3VBNzHPGcwCm/AmberlyLocal?api_key=${apiKey}`).then(res => {
      const records = res.data.records
      let itemMap = {}
      records.forEach(item => {
        // if newly encountered item type make new type list
        if (!(item.fields.type in itemMap)) {
          itemMap[item.fields.type] = []
        }
        itemMap[item.fields.type].push(item)
      });
      setTypeToItemMap(itemMap)
    })
  }, [])
  console.log(typeToItemMap)
  return (
        <div>
        <img src={AmberlyLogo} style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "20%"}}
              />
        <div className="container" style={styles.menuContainer}>
            <div className="row" style={{width: "50%"}}>
                <div className="columns">
                    <div className="column food-column">
                        {Object.keys(typeToItemMap).map((type, i) => {
                          if (i%2 === 0) {
                            return <MenuSection title={type} items={typeToItemMap[type]}/>
                          }
                        })}
                    </div>
                    <div className="column food-column">
                        {Object.keys(typeToItemMap).map((type, i) => {
                          if (i%2 !== 0) {
                            return <MenuSection title={type} items={typeToItemMap[type]}/>
                          }
                        })}
                    </div>
                </div>
            </div>
        </div>   
        </div>
        
  )
}

const MenuSection = (props) => {
  return (
    <div>
      <MenuHeader title={props.title}/>
      {props.items.map(item => <MenuItem key={item.id} name={item.fields.item} price={item.fields.price} description={item.fields.description}/>)}
    </div>
  )
}

const MenuHeader = (props) => {
    return (<div>
        <div style={styles.header}>
            {props.title}
        </div>
        <div style={{fontStyle: "italic", fontSize: "small"}}>
            {props.description}
        </div>
    </div>)
}

const MenuItem = (props) => {
    return ( <div>
            <p style={{float: "left"}}> {props.name} </p>
            <p style={{float: "right"}}> ${props.price} </p>
            <br/>
            <p style={{fontStyle: "italic", fontSize: "small"}}>{props.description}</p>
        </div>
    )
}
const styles = {
  header: {
    fontWeight: "bold",
    fontFamily: "Lulo",
    textDecoration: "underline",
  },
  menuContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}

// MenuPageTemplate.propTypes = {
//   title: PropTypes.string.isRequired,
//   content: PropTypes.string,
//   contentComponent: PropTypes.func,
// }

const MenuPage = () => {
  return (
    <Layout>
      <MenuPageTemplate
      />
    </Layout>
  )
}

export default MenuPage;
